<template>
  <main-map class="map-block"/>
</template>

<script>
import MainMap from '@/components/appealMap/MainMap'
export default {
  name: 'AppealMap',
  components: {
    'main-map': MainMap
  }
}
</script>

<style lang="scss" scoped>
  .map-block{
    width: 100vw;
    height: calc(100vh - 5.1rem);
    position: relative !important;
    z-index: 1;
  }
</style>
