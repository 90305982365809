<template>
  <div>
    <div class="map-menu">
      <div>
      <filter-appeal :showBlockSelect="showLogin"/>
<!--        <router-link to="appeal_forms" class="button_link" v-show="!showLogin"> Подать обращение </router-link>-->
        <a href="https://oprflk.dev.devfbit.ru" class="button_link" v-show="!showLogin"> Подать обращение </a>
      </div>
    </div>
    <l-map
      ref="appealMap"
      :zoom="geoData.zoom"
      :center="geoData.center"
      :options="geoData.mapOptions"
      style="min-height: 100%; position: absolute"
    >
      <l-tile-layer
        :url="geoData.url"
        :attribution="geoData.attribution"
      />
      <marker-cluster>
        <l-geo-json
          :geojson="geoData.geojson"

        />
        <!--      :options="geoJSONOptions"-->
      </marker-cluster>
      <l-control-zoom position="topright"></l-control-zoom>
    </l-map>
  </div>
</template>

<script>
import { latLng, Icon } from 'leaflet'
// import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LGeoJson } from 'vue2-leaflet'
import { LMap, LTileLayer, LGeoJson, LControlZoom } from 'vue2-leaflet'
import MarkerCluster from '../../components/appealMap/MarkerClusterMap'
import 'leaflet/dist/leaflet.css'
import FilterAppeal from '@/components/appeal/FilterAppeal'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../assets/icons/marker-map.svg'),
  iconUrl: require('../../assets/icons/marker-map.svg'),
  // shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowUrl: null,
  iconSize: 45
})

export default {
  name: 'MainMap',
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    // LPopup,
    // LTooltip,
    LGeoJson,
    MarkerCluster,
    LControlZoom,
    FilterAppeal
  },
  data () {
    return {
      showLogin: false,
      geoData: {
        zoom: 11,
        center: latLng(55.753960, 37.620393),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(47.41322, -1.219482),
        withTooltip: latLng(47.41422, -1.250482),
        currentZoom: 11.5,
        currentCenter: latLng(47.41322, -1.219482),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
          zoomControl: false
          // control: {
          //   position: 'bottomleft'
          // }
        },
        showMap: true,
        geojson: {
          type: 'Point',
          coordinates: [-80.1347334, 25.7663562]
        },
        fillColor: '#e4ce7f',
        enableTooltip: false

      }
    }
  },
  mounted () {
    this.geoData.geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              37.820393,
              55.853960
            ]
          },
          properties: {
            number: 35146,
            createdDate: '2021-11-27 17:00:00',
            plannedResolveDate: '2021-12-27 17:00:00',
            status: 'Ожидает рассмотрения',
            title: 'Заголовок обращения',
            topic: 'Тема обращения'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              37.920393,
              55.753960
            ]
          },
          properties: {
            number: 35146,
            createdDate: '2021-11-27 17:00:00',
            plannedResolveDate: '2021-12-27 17:00:00',
            status: 'Ожидает рассмотрения',
            title: 'Заголовок обращения',
            topic: 'Тема обращения'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              37.820393,
              55.653960
            ]
          },
          properties: {
            number: 35146,
            createdDate: '2021-11-27 17:00:00',
            plannedResolveDate: '2021-12-27 17:00:00',
            status: 'Ожидает рассмотрения',
            title: 'Заголовок обращения',
            topic: 'Тема обращения'
          }
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              37.800393,
              55.653960
            ]
          },
          properties: {
            number: 35146,
            createdDate: '2021-11-27 17:00:00',
            plannedResolveDate: '2021-12-27 17:00:00',
            status: 'Ожидает рассмотрения',
            title: 'Заголовок обращения',
            topic: 'Тема обращения'
          }
        }
      ]

    }
  },
  computed: {
    geoJSONOptions () {
      return {
        pointToLayer (feature, latlng) {
          // const icon = 'marker-map.svg'
          // const imgLink = require('../../assets/img/' + icon)
          // return L.marker(latlng, {
          //   icon: new L.Icon({
          //     iconUrl: imgLink, // iconUrl: 'https://drawobjects.admlr.lipetsk.ru/images/' + icon,
          //     iconSize: [40, 40],
          //     iconAnchor: [16.5, 52]
          //   })
          // })
        },
        onEachFeature: this.onEachFeatureFunction
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .marker-cluster {
    box-shadow: 0px 10px 33px rgba(39, 121, 206, 0.33);
    border-radius: 3rem;
    width: 3rem !important;
    height: 3rem !important;

    div {
      display: flex;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border-radius: 1.5rem;
      background: linear-gradient(180deg, #479BF3 0%, rgba(31, 140, 255, 0) 100%), #317FD1;
      border: 2px solid #3F94ED;
      box-sizing: border-box;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #003E78;
        font-size: .875rem;
        font-weight: 700;
        line-height: 1.57;
        background-color: white;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: .9rem;
      }
    }

  }

  .map-menu {
    position: absolute;
    z-index: 500;
    margin-top: 1.6875rem;
    width: 100%;

    >div {
      width: 86.5625rem;
      margin: auto auto;
      background: white;
      border-radius: 3px;
      padding: 1.25rem;
      /*display: flex;*/
    }
  }
  .button_link {
    font-weight: bold;
    font-size: .88rem;
    line-height: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    background: #EB494E;
    border-radius: .19rem;
    padding: .5rem 2.125rem .44rem 2.125rem;
  }

  ::v-deep .footer {
    display: none !important;
  }

  ::v-deep .leaflet-top {
    top: 35vh;
  }
  // Переписываем стили строки фильтра
  ::v-deep .filter-chambers {
    margin: 0;

    & li:nth-child(2), .city_icon {
      margin-bottom: 0;
    }
    .display_filter {
      margin-top: 1rem;
    }
  }
</style>
